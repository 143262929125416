import React from 'react'
import Img from 'gatsby-image'
import useBackend, { toyIds } from '../useBackend'
import Button from './Button'
import { useForm } from '@formspree/react'
import { HomeLink } from './Link'
import tw, { styled } from 'twin.macro'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const StyledImg = styled(Img)`
  ${tw`rounded border-solid border-0`}
`

function TextWrapper({ children }) {
  return (
    <div tw="antialiased text-gray-900 px-6">
      <div tw="max-w-xl mx-auto py-12 md:max-w-4xl">{children}</div>
    </div>
  )
}

export default function ShopFeedback({ thingId }) {
  const { t } = useTranslation()
  const [state, handleSubmit] = useForm('mjvjqdwe')

  const { allItems } = useBackend()

  const item = allItems.filter((item) => item.id === thingId)[0] ?? {}

  const { fixed, params = [] } = item

  if (state.succeeded) {
    return (
      <TextWrapper>
        <h2 tw="text-2xl font-bold">{t('feedback.thanksEnd')} 🙏</h2>
        <HomeLink tw="mt-8" />
      </TextWrapper>
    )
  }

  return (
    <TextWrapper>
      <form onSubmit={handleSubmit}>
        <h2 tw="text-2xl font-bold">{t('feedback.interest')}</h2>

        <input type="hidden" name="thingId" value={thingId} />

        <input type="hidden" name="params" value={JSON.stringify(params)} />

        {fixed && (
          <div tw="mt-4 flex justify-center">
            <div tw="m-auto relative shadow-2xl p-2 bg-green">
              <StyledImg fixed={fixed} alt="" />
              <div tw="absolute bottom-2 left-2">
                {Object.entries(params).map(([k, v]) => (
                  <p key={k} tw="text-xs text-gray-700">
                    {k}: {v}
                  </p>
                ))}
              </div>
            </div>
          </div>
        )}

        <p tw="mt-6 text-lg text-gray-800">{t('feedback.surveyInvite')}</p>

        <p tw="ml-8 text-lg text-gray-800">{t('feedback.usefulQ')}</p>

        <div tw="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6 items-start">
          <div tw="grid grid-cols-1 gap-6">
            <div tw="block">
              <span tw="text-gray-700">{t('feedback.value')}</span>
              <div tw="mt-2">
                <div>
                  <label tw="inline-flex items-center">
                    <input
                      tw="form-radio"
                      type="radio"
                      name="price"
                      value="lt10"
                    />
                    <span tw="ml-2">&lt; {t('feedback.lt15')}</span>
                  </label>
                </div>
                <div>
                  <label tw="inline-flex items-center">
                    <input
                      tw="form-radio"
                      type="radio"
                      name="price"
                      value="15-25"
                    />
                    <span tw="ml-2">{t('feedback.15-25')}</span>
                  </label>
                </div>
                <div>
                  <label tw="inline-flex items-center">
                    <input
                      tw="form-radio"
                      type="radio"
                      name="price"
                      value="25-35"
                    />
                    <span tw="ml-2">{t('feedback.25-35')}</span>
                  </label>
                </div>
                <div tw="flex items-center">
                  <div>
                    <label tw="inline-flex items-center">
                      <input
                        tw="form-radio"
                        type="radio"
                        name="price"
                        value="other"
                      />
                      <span tw="ml-2">{t('feedback.priceOther')}</span>
                    </label>
                  </div>
                  <div tw="ml-4">
                    <input
                      type="text"
                      name="otherprice"
                      tw="form-input mt-1 block w-full"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Question not relevant for toys */}
            {toyIds.indexOf(thingId) === -1 && (
              <div tw="grid grid-cols-1 gap-6">
                <label tw="block">
                  <span tw="text-gray-700">{t('feedback.productBenefit')}</span>
                  <textarea
                    name="problem-text"
                    tw="form-textarea mt-1 block w-full h-24"
                    rows="3"
                    placeholder={t('feedback.textPlaceholder')}
                  ></textarea>
                </label>
              </div>
            )}

            <label tw="block">
              <span tw="text-gray-700">{t('feedback.buyCountry')}</span>
              <input
                name="buyCountry"
                type="text"
                tw="form-input mt-1 block w-full"
              />
            </label>

            <label tw="block">
              <span tw="text-gray-700">{t('feedback.stayTouch')}</span>
              <input
                name="email"
                type="email"
                tw="form-input mt-1 block w-full"
                placeholder={t('feedback.emailPlaceholder')}
              />
            </label>
          </div>
        </div>
        <Button type="submit" disabled={state.submitting} tw="mt-4">
          {t('feedback.submit')}
        </Button>
      </form>
    </TextWrapper>
  )
}
