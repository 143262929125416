import React from 'react'
import { Router } from '@reach/router'
import { SHOP_FEEDBACK } from '../routes'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import ShopFeedback from '../components/ShopFeedback'
import { graphql } from 'gatsby'

export default function ShopFeedbackPage() {
  return (
    <Layout>
      <Seo title="cherrykit" />
      {/* Version for default language (no /en/ in path) */}

      <Router basepath={`${SHOP_FEEDBACK}`}>
        <ShopFeedback path="/:thingId" />
      </Router>
      {/* Version for explicit language (/de/ in path) */}
      <Router basepath={`/:lang${SHOP_FEEDBACK}`}>
        <ShopFeedback path="/:thingId" />
      </Router>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
