import React, { forwardRef } from 'react'
import tw from 'twin.macro'

export default forwardRef(({ children, disabled, ...props }, fwdRef) => {
  return (
    <button
      ref={fwdRef}
      disabled={disabled}
      css={[
        disabled && tw`opacity-50 cursor-not-allowed`,
        tw`bg-blue-link hover:bg-blue-link text-white font-bold rounded p-2 px-6 flex items-center justify-center`,
      ]}
      {...props}
    >
      {children}
    </button>
  )
})
